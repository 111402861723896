<template>
  <div class="mt-5">
    <Navbar />    
    <Section title="How BEX works?" small-text="Learn how to start selling on BEX in 6 different steps" component-name="HowWorksContent" />    
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '../components/Navbar.vue'
import Section from '../components/Section.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'HowItWorks',
  components: {
    Navbar,    
    Section,
    Footer
  }
}
</script>